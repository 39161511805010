
<script>
import { template } from '@shein/common-function'  
import schttp from 'public/src/services/schttp'
  
export default {
  data: () => ({
    allCountries: [],
    mbwayInfo: {
      dialogShow: false,
      code: '',
      phone: '',
      isFocus: false,
      errTip: '',
    },
    blikCode: {
      pending: false,
      loading: false,
      codeList: ''
    },
    showPayResult: false,
  }),
  computed: {
    // 站点mall 多mall站样式按多mall来. 
    isSiteMallList () { 
      return this.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
    },
    mall_store_infos () {
      let mallStoreInfos = []
      if ([2, 5].includes(this.nowPageType) || ['XtraDetail'].includes(this.nowCheckoutScene)) { 
        mallStoreInfos = this.orderInfo?.mall_list || []
      } else if (this.nowPageType == 1) {
        mallStoreInfos = this.checkout?.mall_caculate_info?.mall_store_infos || []
      }
      return mallStoreInfos
    },
    // 过滤虚拟商品后的购物车商品业务模式+主体id集合
    filterVirtualGoodsCartListInfo() {
      let list = []
      if (this.nowPageType == 1) {
        list = this.checkout?.results?.carts?.carts || []
      } else if ([2, 5].includes(this.nowPageType)) {
        list = this.filterVirtualGoodsList(this.orderInfo) || []
      }
      // 需去重
      let hashMap = {}
      return list.map(v => ({ businessModel: v.business_model, companyId: v.company_id })).reduce((pre, cur) => {
        const hash = `${cur.businessModel}:${cur.companyId}`
        if (!hashMap[hash]) {
          hashMap[hash] = true
          return [...pre, cur]
        }
        return pre
      }, [])
    },
    // 获取支付方式列表时的order_details
    // 需过滤虚拟商品
    getPaymentsOrderDetails() {
      let list = []
      if (this.nowPageType == 1) { // 普通｜随单购一次下单
        list = this.checkout?.results?.carts?.carts || []
      } else if ([2, 5].includes(this.nowPageType) || this.nowCheckoutScene === 'XtraDetail') { // 普通｜随单购订单详情/订单列表 超省卡订单详情
        // list = this.filterVirtualGoodsList(this.orderInfo) || []
        list = this.orderInfo?.mall_list || []
      }
      return this.uniqueBusinessInfo(list.map(v => ({ businessModel: v.business_model, companyId: v.company_id })))
    },
    // 创建session时的order_details
    // 需要加上超省卡/付费会员的业务模式传给后端
    createSessionOrderDetails () {
      let list = []
      if (this.nowPageType == 1) { // 普通｜随单购一次下单
        list = (this.checkout?.results?.carts?.carts || []).map(v => ({ businessModel: v.business_model, companyId: v.company_id }))
        const choosePrimeProductInfo = this.usedPrimeIndex > -1 && this.primeProductList?.length ? this.primeProductList?.[this.usedPrimeIndex] : null
        const chooseXtraProductInfo = this.usedXtraCode && this.xtraProductInfo || null
        if (choosePrimeProductInfo) {
          list.push({ businessModel: choosePrimeProductInfo.business_model })
        }
        if (chooseXtraProductInfo) {
          list.push({ businessModel: chooseXtraProductInfo.business_model })
        }
      } else if ([2, 5].includes(this.nowPageType) || this.nowCheckoutScene === 'XtraDetail') { // 订单列表 普通｜随单购订单详情/超省卡单独购订单详情
        list = (this.orderInfo?.mall_list || []).map(v => ({ businessModel: v.business_model, companyId: v.company_id }))
      } else if (this.nowCheckoutScene === 'Xtra' || this.nowCheckoutScene === 'BuyPrime') { // 超省卡｜付费会员单独购一次下单
        if (this.primePriceInfo?.business_model != null) {
          list = [{ businessModel: this.primePriceInfo?.business_model }]
        }
      } else if (this.checkout?.results?.order_details_model?.length){
        list = this.checkout?.results.order_details_model
      }
      return this.uniqueBusinessInfo(list)
    },
  },
  methods: {
    getRiskifiedDeviceid() {
      const riskifiedInfo = window.TPM?.runImmediate({
        marketing: 'Riskified',
        method: '_getRiskSessionId',
      })
      if(riskifiedInfo) {
        window.riskifiedDeviceId = riskifiedInfo?.datas || ''
      }
    },
    filterPrimePayments({ paymentMethods = [], isChoosePrime, isChooseXtra }){
      let newPayments = paymentMethods.concat([])
      newPayments.forEach(item => {
        // 已经因为其他原因置灰不可用，则不进行后续处理
        if (!item.enabled) return
        // 超省卡优先级 > 付费会员
        if(isChooseXtra) {
          if (item.support_xtra_with_order == 0) {
            item.enabled = 0
            // 其中支付方式置灰文案存在优先级判断， 平台配置不可用（paycenter）＞cod不可用的原因＞付费会员的不可用原因
            item.gray_description = template( item.title, this.language.SHEIN_KEY_PWA_26132)
          }
        } else if(isChoosePrime) {
          if (item.support_membership_with_order == 0) {
            item.enabled = 0
            // 其中支付方式置灰文案存在优先级判断， 平台配置不可用（paycenter）＞cod不可用的原因＞付费会员的不可用原因
            item.gray_description = template( item.title, this.language.SHEIN_KEY_PWA_21864)
          }
        } 
        if(isChooseXtra && isChoosePrime){
          if (item.support_membership_with_order == 0 && item.support_xtra_with_order == 0 ) {
            item.enabled = 0
            // 其中支付方式置灰文案存在优先级判断， 平台配置不可用（paycenter）＞cod不可用的原因＞付费会员的不可用原因
            item.gray_description = this.language.SHEIN_KEY_PWA_31928
          }
        }
      })
      return newPayments
    },
    /**
     * 增加本地自营
     * (1）查车 => 确认业务 => businessTypeCode(自定义)【0跨境自营，无过滤。1纯店铺，取店铺无需过滤本地自营。2无店铺，有本地自营，只取本地自营。3有店铺，有本地自营，需要过滤掉本地自营】
     * (2) 查支付方式列表 => support_platform(1是0否支持平台) support_xxx(是否支持本地)
     * (3) poskey => businessModeNewS self(过滤跨境自营) store(过滤店铺) local(过滤本地自营)
     */
    filterPaymentsByBusinessModePayAbt ( { paymentMethods = [], abtInfo = {}, formatDetail }){
      let newPayments = paymentMethods.concat([])
      const businessModePayAbtInfo = abtInfo
      // 普通下单对勾选虚拟商品进行过滤
      let virtualData = []
      if(this.nowPageType == 1 && formatDetail){
        if (typeof formatDetail === 'string') {
          virtualData = formatDetail.split(',').map(v => {
            const [business_model = 0, company_id] = v.split(':')
            return { business_model, company_id }
          })
        }
      }
      try{
        let mallInfos = [...(this.checkout?.results?.carts?.carts || []), ...virtualData]
        // 详情/列表 取orderInfo里的数据
        if ([2, 5].includes(this.nowPageType)) {
          mallInfos = this.orderInfo?.mall_list || []
        }
        // 业务模式区别
        const businessType = ({ mallInfos }) => {
          const businessMap = (item) => item?.business_model || item?.businessModel
          if(mallInfos.some(s => businessMap(s) == 1 || businessMap(s) == 4)) return 'store'
          if(mallInfos.some(s => (s?.business_model == 0 && s?.company_id == 75) || (s?.businessModel == 0 && s?.companyId == 75))) return 'local'
          return 'self'
        }

        const abtResult = {}
        businessModePayAbtInfo?.param && Object.entries(businessModePayAbtInfo.param).map(item => {
          return abtResult[item[0]] = item[1].split(',')
        })
        // abt过滤，无则不过滤，过滤掉abt参数上的支付方式
        const businessTypeCode = mallInfos?.length ? businessType({ mallInfos }) : ''
        // 如果没有匹配上任意一个业务模式，则直接返回原列表
        if (!businessTypeCode) return newPayments
        let abtPayments = newPayments.filter(payment => !abtResult?.[businessTypeCode]?.length || !abtResult?.[businessTypeCode]?.includes(payment.code))
        // 支付方式至少得有一个可用，否则不过滤
        abtPayments = abtPayments.every(s => s.enabled == 0) ? newPayments : abtPayments

        return abtPayments.length ? abtPayments : newPayments
      }catch(e){
        console.log('businessModePayAbt format error', e)
      }
    },

    forterLoad() {
      window.TPM?.run({
        marketing: 'Forter',
        method: '_loadFort',
      })
    },
    async showMbwayDialog() {
      let countryId = ''
      let phone = ''
      if (this.nowPageType == 1) {
        countryId = this.checkout?.default_address?.countryId
        phone = this.checkout.default_address.tel
      } else if ([2, 4, 5].includes(this.nowPageType) || ['XtraDetail'].includes(this.nowCheckoutScene)) {
        countryId = this.orderInfo?.shipping_country_id
        phone = this.orderInfo?.shipping_telephone
      } else if ([3, 6].includes(this.nowPageType) || ['Xtra'].includes(this.nowCheckoutScene)) {
        countryId = this.defaultAddress.countryId
        phone = this.defaultAddress.tel
      }
  
      let allCountries = this.allCountries || []
      //请求所有国家获取电话区号
      if (!allCountries.length) {
        allCountries = await schttp({
          url: '/api/common/country/query'
        }).then(res => {
          Vue.set(this, 'allCountries', res || [])
          return res || []
        }).catch(() => {
          return []
        })
      }
      let target = allCountries.filter((item) => item.id == countryId)
      target = target?.[0] || {}
  
      //展示电话弹窗
      this.mbwayInfo.dialogShow = true
      this.mbwayInfo.phone = phone
      this.mbwayInfo.code = target.phone_code
    },
    async confirmMbwayPhone() {
      let area_abbr = ''
  
      if (this.nowPageType == 1) {
        area_abbr = this.checkout.default_address.value
      } else if ([2, 4, 5].includes(this.nowPageType) || ['XtraDetail'].includes(this.nowCheckoutScene)) {
        area_abbr = this.orderInfo?.countryCode
      } else if ([3, 6].includes(this.nowPageType) || ['Xtra'].includes(this.nowCheckoutScene)) {
        area_abbr = this.defaultAddress?.value
      }
  
      const data = {
        area_abbr,
        alias: this.mbwayInfo.phone,
        area_code: parseInt(this.mbwayInfo.code),
        alias_type: 2,
      }
      this.mbwayConfirmSa && this.mbwayConfirmSa()
      const res = await schttp({
        url: '/api/auth/phoneValidate/get',
        method: 'POST',
        data
      }).catch(() => {
        return {}
      })
      if (res?.code != 0) {
        this.mbwayInfo.errTip = this.language.SHEIN_KEY_PWA_20120
        return
      }
      this.confirmValid = true
      this.mbwayInfo.dialogShow = false
  
      if ([1, 3, 4, 6].includes(this.nowPageType) || ['Xtra'].includes(this.nowCheckoutScene)) {
        this.createOrder({ mbwayConfirValid: true })
      } else if ([2, 5].includes(this.nowPageType) || ['XtraDetail'].includes(this.nowCheckoutScene)) {
        this.handlePaynow({ mbwayConfirValid: true })
      }
    },
    mbwayInputFocus() {
      this.mbwayInfo.isFocus = true
      this.mbwayInfo.errTip = ''
    },
  
    pageOnload(saPageInfo) {
      if (!saPageInfo || !appEventCenter) return
      $(() => {
        window.SaPageInfo = saPageInfo
        appEventCenter.$emit('pageOnload')
      })
    },
    // 购物车商品业务模式+主体id数据集处理
    // 过滤虚拟商品后的列表  => 1p 之后无过滤虚拟商品
    filterVirtualGoodsList (order = {}) {
      const { mall_list = [] } = order || {}
      return mall_list
      // 付费会员包/超省卡
      // const virtualGoodsList = (orderGoodsList?.flat?.(Infinity) || []).filter(v => v.is_prime_goods || v.is_xtra_goods) || []
      // const isVirtualGoods = (goodsInfo, virtualMall) => goodsInfo?.store_code === virtualMall?.store_code && goodsInfo?.mall_code === virtualMall?.mall_code
      // return (mall_list || []).filter(v => !virtualGoodsList.some(item => isVirtualGoods(v, item)))
    },
    // 去重
    uniqueBusinessInfo (list = []) {
      // 需去重
      let hashMap = {}
      return list.reduce((pre, cur) => {
        const hash = `${cur.businessModel}:${cur.companyId || ''}`
        if (!hashMap[hash]) {
          hashMap[hash] = true
          return [...pre, cur]
        }
        return pre
      }, [])
    },
  },
}
</script>
  
